<template>
  <div class="parent">
    <label class="div2">Creo que estas perdido...</label>
    <v-img
      class="div1"
      src="https://townsquare.media/site/690/files/2020/09/Chicken-suriyasilsaksom.jpeg"
      max-width="40%"
    />
  </div>
</template>
<script>
import eventBus from "../EventBus";

export default {
  name: "404",
  data: () => ({}),
  mounted() {
    eventBus.$emit("title-select", "ERORR 404");
  },
};
</script>
<style>
.parent {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: center;
}
.div1 {
  grid-area: 1 / 1 / 9 / 8;
  position: relative;
  z-index: 1;
}

.div2 {
  grid-area: 4 / 1 / 6 / 8;
  position: relative;
  z-index: 2;
}
</style>
